import React, { Component }  from 'react';
import './App.css';

import DASH from '../src/landingPage2/index';

function App() {
  return (
    <>
    <div className="page">
      <DASH/>
    </div>
    </>
  );
}

export default App;
